import {HttpClient} from '@angular/common/http';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {RadioItem} from '@app/sam-base/models/components/radio-item.model';
import {environment} from '@root/environments/environment';
import {AnswerType} from '@sam-base/models/admin/answer-type.enum';
import {DialogResponseState} from '@sam-base/models/admin/dialog-response-state.enum';
import {DialogResponse} from '@sam-base/models/admin/dialog-response.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {Subject} from 'rxjs';

/**
 * Tab Dialog of swissdec institution in version 5
 */
@Component({
    selector: 'iw-tab-sasx5institution-dialog',
    templateUrl: './tab-sasx5institution-dialog.component.html'
})
export class TabSasx5institutionDialogComponent extends BasePartialFormComponent<Sasx5institution> implements OnInit, OnDestroy {

    public ANSWER_TYPE = AnswerType;
    public DIALOG_RESPONSE_STATE = DialogResponseState;
    public yesNoOptions: RadioItem<string | boolean>[] = [
        {
            label: 'yes',
            value: 'YES'
        },
        {
            label: 'no',
            value: 'NO'
        },
        {
            label: 'unknown',
            value: 'UNKNOWN'
        }
    ];
    public dialogResponse: DialogResponse | undefined;
    protected readonly DialogResponseState = DialogResponseState;
    protected readonly AnswerType = AnswerType;
    private readonly _sasx5institutionRest: RestEntityClient<Sasx5institution>;
    private subscriptions = new Subject();

    constructor(private readonly _restService: RestApiService, private readonly _toast: ToastService,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private readonly _http: HttpClient) {
        super();
        this._sasx5institutionRest = _restService.getEntityClient(Sasx5institution);
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get isSaveDialogDisabled() {
        return this.dialogResponse?.state !== DialogResponseState.SUCCESS && this.getFormValue('processFlow') === SxProcessFlow.DIALOG_AND_RESULT;
    }

    public get isReplyDialogDisabled() {
        // eslint-disable-next-line max-len
        const sections = this.dialogResponse?.dialogMessages?.flatMap((dialogMessage) => dialogMessage.sections);
        // console.log('Sections:', sections);
        const paragraphs = sections?.flatMap((section) => section?.paragraphs);
        // console.log('Paragraphs:', paragraphs);
        // eslint-disable-next-line max-len
        const mandatoryFieldsMissing = paragraphs?.filter((paragraph) => !!paragraph && !paragraph.readonly && !paragraph.optional && paragraph.type !== AnswerType.BOOLEAN && paragraph.type !== AnswerType.LABEL && !paragraph.value);
        // console.log('Mandatory fields missing:', mandatoryFieldsMissing);
        const disableReplyState = this.dialogResponse?.state !== DialogResponseState.SUCCESS || this.getFormValue('processFlow') !== SxProcessFlow.DIALOG_AND_RESULT || paragraphs?.length === 0 // if no answer to respond to
            || mandatoryFieldsMissing?.length || 0 > 0;
        // console.log('Disable reply state:', disableReplyState);
        return disableReplyState;
    }

    public ngOnInit() {
        this.getDialogMessage();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getDialogMessage(forced: boolean = false) {
        return this._http.get<DialogResponse>(environment.backendURL + `swissdec/institutions/${(this.entityId)}/get_dialog`, {params: {forced}})
            .subscribe(dial => {
                this._toast.success('get_dialog_success');
                this.refreshFormMessageService.sendMessage('institution.refresh');
                this.dialogResponse = dial;
            }, (err) => {
                this._toast.error('get_dialog_error');
            });

    }

    public saveDialogMessage() {
        return this._http.put<DialogResponse>(environment.backendURL + `swissdec/institutions/${(this.entityId)}/dialog`, this.dialogResponse)
            .subscribe(dial => {
                this._toast.success('save_dialog_success');
                this.refreshFormMessageService.sendMessage('institution.refresh');
                this.dialogResponse = dial;
            }, (err) => {
                this._toast.error('save_dialog_error');
            });

    }

    public replyDialog() {
        return this._http.post<DialogResponse>(environment.backendURL + `swissdec/institutions/${(this.entityId)}/reply_dialog`, this.dialogResponse)
            .subscribe(inst => {
                this._toast.success('reply_dialog_success');
                this.refreshFormMessageService.sendMessage('institution.refresh');
                this.dialogResponse = inst;
            }, (err) => {
                this._toast.error('reply_dialog_error');
            });
    }
}
