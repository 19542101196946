<div class="row">
    <div class="col-xs-12">
        <div class="row">
            <div class="col-xs-12">
                <h2>{{ 'included_employees_for_declaration' | translate }}</h2>
            </div>
        </div>
        <div class="row">
            <iw-button (press)="removeEmployee()"
                       [isDisabled]="isReadonly || removeEmployeeDisabled"
                       [text]="'remove_employee' | translate"
                       class="col-xs-offset-9 col-xs no-padding"></iw-button>

        </div>
        <div class="row">
            <iw-textfield #txtNbSalBuffErrorCount
                          [isDisabled]="true"
                          [labelAlign]="'left'"
                          [label]="'error' | translate"
                          [textAlign]="'center'"
                          [value]="nbEmployeeErrorCount"
                          class="iw-input col-xs-offset-6 col-xs-4 no-padding"></iw-textfield>
            <iw-textfield #txtNbSalBuffSelectedCount
                          [isDisabled]="true"
                          [textAlign]="'center'"
                          [value]="nbEmployeeSelectedCount"
                          class="iw-input col-xs-2 no-padding"></iw-textfield>
        </div>
        <div class="row">
            <div class="col-xs-12 no-padding"
                 style="height: 200px;">
                <iw-table #employeesGrid
                          (applyFilter)="onApplyFilter($event)"
                          (contextMenuClick)="onContextMenu($event)"
                          (rowClicked)="onEmployeeClicked($event)"
                          (rowDoubleClick)="editEmployee($event)"
                          (selectedChange)="setSelected($event)"
                          [columns]="employeeGridColumns"
                          [data]="filteredEmployees"
                          [defaultSelected]="true"
                          [isFilterEnable]="true"
                          [isSortIconEnable]="true"
                          [selectionMode]="'checkbox'"
                          [virtualScroll]="true"></iw-table>
                <iw-context-menu #menu
                                 [context]="contextMenuData"
                                 [items]="contextMenuItems"
                                 [popup]="true"></iw-context-menu>
            </div>
        </div>
        <div *ngIf="selectedEmployee?.error"
             class="row">
            <div [innerHTML]="selectedEmployeeErrorMessage"
                 class="iw-textarea col-xs-12"></div>
        </div>

    </div>
</div>
