<div class="row">
    <div class="col-xs-12">
        <div style="height: 200px;">
            <iw-table #institutionsGrid
                      (applyFilter)="onApplyFilter($event)"
                      (rowDoubleClick)="onRowDoubleClick($event)"
                      [columns]="institutionGridColumns"
                      [data]="filteredInstitutions"
                      [defaultSelected]="true"
                      [isFilterEnable]="true"
                      [isSortIconEnable]="true"
                      [virtualScroll]="true"
                      id="institutionsGrid"></iw-table>
        </div>
    </div>
</div>

