<div class="row">
    <iw-button (press)="declareSalary()"
               [text]="'declare_salary' | translate"
               class="btn-block"></iw-button>
    <!--  [isDisabled]="declareSalaryDisabled"-->
</div>
<div class="row">
    <!--    [isDisabled]="getStatusDisabled"-->

    <iw-button (press)="getDeclarationStatus()"
               [text]="'get_declaration_status' | translate"
               class="btn-block"></iw-button>
</div>