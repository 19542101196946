import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {IwGridColumn} from '@sam-base/models';

import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {Saparass} from '@sam-base/models/placement/saparass';
import {Subject} from 'rxjs';

/**
 * Tab General of Employee - SAM-4268
 */
@Component({
    selector: 'iw-tab-sasx5dec-assurances',
    templateUrl: './tab-sasx5dec-assurances.component.html'
})
export class TabSasx5decAssurancesComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {


    public insurances: Saparass[] = [];
    public insurancesColumnToShow: IwGridColumn<Saparass>[] = [
        {
            prop: 'dedCode',
            name: 'dedCode',
        },
        {
            prop: 'assNom',
            name: 'assNom',
        },
        {
            prop: 'annee',
            name: 'annee',
        }
    ];

    @Input() public isNew = false;
    private subscriptions = new Subject();

    constructor(private readonly _swissdecService: SwissdecService) {
        super();
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get annee() {
        return this.getFormValue('annee') as string;
    }

    public get gestion(): string {
        return this.getFormValue('gestion') as string;
    }

    public ngOnInit() {
        this.subscribeValueChange('annee', annee => {
            this.fetchInsuranceByGestionAndYear(this.gestion, this.annee);
        });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public convertSaparassToInstitution(saparass: Saparass): Sasx5institution {
        const institution: Sasx5institution = new Sasx5institution();
        institution.domain = saparass.dedCode;
        institution.institutionCode = saparass.assCode;
        institution.institutionIdref = saparass.assIdref;
        institution.assNocli = saparass.assNocli;
        institution.assNocai = saparass.assNocai;
        institution.assNocta = saparass.assNocta;
        institution.assNom = saparass.assNom;
        institution.incompleteDeclaration = saparass.incompleteDeclaration;
        return institution;
    }

    public selectAssuranceChange(selectedAssurances: Saparass[]) {
        this.setFormValue('institutions', selectedAssurances.map(this.convertSaparassToInstitution));
    }

    private fetchInsuranceByGestionAndYear(gestion: string, annee: string) {
        this._swissdecService.getInsurancesForGestionAndYear(gestion, annee).subscribe(saparAssTab => this.insurances = saparAssTab);
    }
}
