import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';

export enum OperationType {
    CREATE_SALARY = 'CREATE_SALARY',
    CALCULATE_SALARY = 'CALCULATE_SALARY',
    PUBLISH_SALARY = 'PUBLISH_SALARY',
    EMAIL_SALARY = 'EMAIL_SALARY',
    EMAIL_YEAR_SALARIES = 'EMAIL_YEAR_SALARIES',
    PUBLISH_INVOICE = 'PUBLISH_INVOICE',
    EMAIL_INVOICE = 'EMAIL_INVOICE',
    VALIDATE_WAITING_PAYMENT = 'VALIDATE_WAITING_PAYMENT'
}

export enum OperationStatus {
    NEW = 'NEW',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}

export class Operation {
    id?: number;
    type?: OperationType;
    status?: OperationStatus;
    parentId?: number;
    submittedBy?: string;
    submittedAt?: string;
    entityId?: string;
    entityDescription?: string;
    data?: string;
    dueAt?: string;
    lockedBy?: number;
    lockedAt?: string;
    startedAt?: string;
    endedAt?: string;
    error?: BusinessExceptionErrors;
    nbOfExecutions?: number;
    maxNbOfExecutions?: number;
    dateCreat?: string;
    userCreat?: string;
    dateModif?: string;
    userModif?: string;
    version?: number;
}


export class OperationDetail {
    operation?: Operation;
    subOperations?: Operation[];
}