<div class="row">
    <div *ngIf="annee"
         class="col-xs-12">
        <div style="height: 200px;">
            <iw-table #assurancesGrid
                      (selectedChange)="selectAssuranceChange($event)"
                      [columns]="insurancesColumnToShow"
                      [data]="insurances"
                      [isFilterEnable]="false"
                      [selectionMode]="'checkbox'"
                      id="assurancesGrid"></iw-table>
        </div>
    </div>
</div>

