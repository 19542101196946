<form [formGroup]="formGroup"
      autocomplete="no"
      class="row spaceForm">
    <div class="form-host row col-xs-12">
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'classer_sous' | translate"
                      [size]="'large'"
                      class="iw-input col-xs-6 col-no-padding padding-right-10"
                      formControlName="cliIndex"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'consolider' | translate"
                      [size]="'large'"
                      class="iw-input col-xs-6 col-no-padding"
                      formControlName="pgnIndex"></iw-textfield>
    </div>

    <iw-gecligrp-dropdown #cbogecligrp
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'groupe' | translate"
                          [size]="'large'"
                          [width]="'100%'"
                          class="iw-input col-xs-6"
                          formControlName="cligrpId"
                          id="cbo-gecligrp"></iw-gecligrp-dropdown>
    <div class="col-xs-6"></div>

    <iw-gepaimode-dropdown #cbogebvrmpaid
                           [isDisabled]="isReadonly"
                           [labelAlign]="'left'"
                           [label]="'foudp' | translate"
                           [onlyLbvr]="true"
                           [size]="'large'"
                           [width]="'100%'"
                           class="iw-input col-xs-6"
                           formControlName="bvrMpaId"
                           id="cbo-gebvrmpaid"></iw-gepaimode-dropdown>
    <div class="col-xs-6"></div>

    <iw-gepaicond-dropdown #cbogepaicond
                           [isDisabled]="isReadonly"
                           [labelAlign]="'left'"
                           [label]="'paicond' | translate"
                           [size]="'large'"
                           [width]="'100%'"
                           class="iw-input col-xs-6"
                           formControlName="paicondId"
                           id="cbo-gepaicond"></iw-gepaicond-dropdown>
    <div class="col-xs-6"></div>

    <iw-textfield [isDisabled]="isReadonly"
                  [labelAlign]="'left'"
                  [label]="'crelimite' | translate"
                  [size]="'large'"
                  class="iw-input col-xs-6 text-align-right"
                  formControlName="crelimite"></iw-textfield>

    <iw-textfield [isDisabled]="isReadonly"
                  [labelAlign]="'left'"
                  [label]="'rabaisRemise' | translate"
                  [size]="'large'"
                  class="iw-input col-xs-6 text-align-right"
                  decimals="2.0"
                  formControlName="escompte"
                  type="number"></iw-textfield>

    <iw-enum-dropdown #cboFormeJuri
                      [inputEnum]="formejuri"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'formeJuridique' | translate"
                      [size]="'large'"
                      class="iw-input col-xs-6"
                      formControlName="formejuri"></iw-enum-dropdown>
    <div class="col-xs-6"></div>

    <iw-enum-dropdown #cboLangue
                      [inputEnum]="langue"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'langue' | translate"
                      [size]="'large'"
                      class="iw-combo-box col-xs-6"
                      formControlName="langue"></iw-enum-dropdown>
    <div class="col-xs-6"></div>

    <iw-textfield [isDisabled]="isReadonly"
                  [labelAlign]="'left'"
                  [label]="'nbexfact' | translate"
                  [size]="'large'"
                  class="iw-input col-xs-6 text-align-right"
                  decimals="0.0"
                  formControlName="nbexfact"
                  type="number"></iw-textfield>

    <iw-checkbox #Standardlogic1
                 [isDisabled]="isReadonly"
                 [label]="'hors_tva' | translate"
                 [size]="'large'"
                 class="iw-input col-xs-6 margin-top-5"
                 formControlName="horsTva"></iw-checkbox>

    <iw-gecliadr-dropdown #cboCliadr_id
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'adresse_factures' | translate"
                          [resultFilters]="resultFilters"
                          [size]="'large'"
                          class="iw-input col-xs-8"
                          formControlName="facadrId"></iw-gecliadr-dropdown>

    <iw-textfield-gecliatt #txtResp
                           [cliId]="entityId"
                           [isDisabled]="!entityId || isReadonly"
                           [labelAlign]="'left'"
                           [label]="'factures_attn' | translate"
                           [size]="'large'"
                           class="iw-input col-xs-8"
                           formControlName="facAttn"></iw-textfield-gecliatt>

    <iw-template-dropdown #drpContMis
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'format_factures' | translate"
                          [report]="'FAC'"
                          [size]="'large'"
                          class="iw-combobox col-xs-8"
                          formControlName="facfrx"></iw-template-dropdown>

    <iw-gecliadr-dropdown #cboCliadr_id
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'adresse_rappels' | translate"
                          [resultFilters]="resultFilters"
                          [size]="'large'"
                          class="iw-input col-xs-8"
                          formControlName="rpladrId"></iw-gecliadr-dropdown>

    <iw-textfield-gecliatt #txtResp
                           [cliId]="entityId"
                           [isDisabled]="!entityId || isReadonly"
                           [labelAlign]="'left'"
                           [label]="'rappels_attn' | translate"
                           [size]="'large'"
                           class="iw-input col-xs-8"
                           formControlName="rplAttn"></iw-textfield-gecliatt>

    <iw-template-dropdown #drpContMis
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'format_rapels' | translate"
                          [report]="'RPL'"
                          [size]="'large'"
                          class="iw-combobox col-xs-8"
                          formControlName="rplfrx"></iw-template-dropdown>
    <div class="col-xs-4"></div>

    <iw-checkbox #Standardlogic1
                 [isDisabled]="isReadonly"
                 [label]="'send_rappel' | translate"
                 [size]="'large'"
                 class="iw-input col-xs-3 col-xs-offset-3 margin-top-5"
                 formControlName="sendrappel"></iw-checkbox>

    <iw-enum-dropdown #cboLangue
                      [inputEnum]="addresses"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'' | translate"
                      [size]="'large'"
                      class="iw-combo-box col-xs-2 "
                      formControlName="rpladrmode"></iw-enum-dropdown>
    <div class="col-xs-4"></div>

    <iw-checkbox #Standardlogic1
                 [isDisabled]="isReadonly"
                 [label]="'fact_email' | translate"
                 [size]="'large'"
                 class="iw-input col-xs-3 margin-top-5"
                 formControlName="facbyemail"></iw-checkbox>

    <iw-textfield [isDisabled]="isReadonly"
                  [labelAlign]="'left'"
                  [label]="'' | translate"
                  [size]="'large'"
                  class="iw-input col-xs-5"
                  formControlName="facemail"></iw-textfield>

    <iw-template-dropdown #drpContMis
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'format_factures_email' | translate"
                          [report]="'FAC'"
                          [size]="'large'"
                          class="iw-combobox col-xs-8"
                          formControlName="facfrxem"></iw-template-dropdown>
    <div class="col-xs-4"></div>

    <iw-checkbox #Standardlogic2
                 [isDisabled]="isReadonly"
                 [label]="'rpl_email' | translate"
                 [size]="'large'"
                 class="iw-input col-xs-3 margin-top-5"
                 formControlName="rplbyemail"></iw-checkbox>

    <iw-textfield [isDisabled]="isReadonly"
                  [labelAlign]="'left'"
                  [label]="'' | translate"
                  [size]="'large'"
                  class="iw-input col-xs-5"
                  formControlName="rplemail"></iw-textfield>

    <iw-template-dropdown #drpContMis
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'format_rappel_email' | translate"
                          [report]="'RPL'"
                          [size]="'large'"
                          class="iw-combobox col-xs-8"
                          formControlName="facfrxem"></iw-template-dropdown>
    <div class="col-xs-4"></div>


</form>