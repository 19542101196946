<form [formGroup]="formGroup">
    <div class="row">
        <p-scrollPanel [style]="{width: '100%', height: '40vh'}">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col-xs-12">
                        <h4>Dialog Communication</h4>
                    </div>
                    <!-- HANDLE DIALOG MESSAGES  -->
                    <div *ngIf="dialogResponse"
                         class="row">

                        <div [ngSwitch]="dialogResponse.state">
                            <div *ngSwitchCase="DIALOG_RESPONSE_STATE.ERROR">
                                <h2>ERROR</h2><br>
                                <h4>User Information</h4><br>
                                <p>{{ dialogResponse.endUserInformation }}</p><br>
                                <h4>Details Information</h4>
                                <p>{{ dialogResponse.detailInformation }}</p>
                            </div>
                            <div *ngSwitchCase="DIALOG_RESPONSE_STATE.PROCESSING">
                                <h2>PROCESSING</h2><br>
                                <iw-date-picker #txtDateFrom
                                                [isDisabled]="true"
                                                [labelAlign]="'top'"
                                                [label]="'Expected to be done for'"
                                                [value]="dialogResponse.expectedAvailability"
                                                class="iw-input col-xs-7"></iw-date-picker>


                                <p>{{ dialogResponse.endUserInformation }}</p><br>
                                <h4>Details Information</h4>
                                <p>{{ dialogResponse.detailInformation }}</p>
                            </div>
                            <div *ngSwitchCase="DIALOG_RESPONSE_STATE.SUCCESS">
                                <div *ngFor="let dialog of dialogResponse.dialogMessages">

                                    <h1>{{ dialog.title }}</h1><br>
                                    <p>Creation date : {{ dialog.creation | date }}</p><br>
                                    <p>{{ dialog.description }}</p><br>
                                    <div *ngFor="let section of dialog.sections"
                                         class="row">
                                        <h2 class="col-xs-12">{{ section.heading }}</h2>
                                        <p class="col-xs-12">{{ section.description }}</p>
                                        <div *ngFor="let paragraph of section.paragraphs"
                                             class="col-xs-12">
                                            <div [ngSwitch]="paragraph.type">
                                                <div *ngSwitchCase="ANSWER_TYPE.STRING">
                                                    <iw-textfield [(value)]="paragraph.value"
                                                                  [isDisabled]="paragraph.readonly"
                                                                  [label]="paragraph.label"
                                                                  class="col-xs-7"
                                                                  labelAlign="top"
                                                                  type="text"></iw-textfield>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.INTEGER">
                                                    <iw-textfield [(value)]="paragraph.value"
                                                                  [decimals]="0"
                                                                  [isDisabled]="paragraph.readonly"
                                                                  [label]="paragraph.label"
                                                                  [stepValue]="1"
                                                                  class="col-xs-7"
                                                                  labelAlign="top"
                                                                  type="number"></iw-textfield>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.DOUBLE">
                                                    <iw-textfield [(value)]="paragraph.value"
                                                                  [isDisabled]="paragraph.readonly"
                                                                  [label]="paragraph.label"
                                                                  class="col-xs-7"
                                                                  decimals="2.0"
                                                                  labelAlign="top"
                                                                  type="number"></iw-textfield>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.AMOUNT">
                                                    <iw-textfield [(value)]="paragraph.value"
                                                                  [isDisabled]="paragraph.readonly"
                                                                  [label]="paragraph.label"
                                                                  class="col-xs-7"
                                                                  decimals="2.0"
                                                                  labelAlign="top"
                                                                  type="number"></iw-textfield>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.BOOLEAN">
                                                    <iw-checkbox [(value)]="paragraph.value"
                                                                 [isDisabled]="paragraph.readonly"
                                                                 [label]="paragraph.label"
                                                                 class="iw-input col-xs-7"
                                                                 labelAlign="top"></iw-checkbox>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.YES_NO">
                                                    <div [title]="paragraph.label"
                                                         class="iw-form-group-label"
                                                         for="yesno">
                                                        {{ paragraph.label }}
                                                    </div>
                                                    <iw-radio-list #yesno
                                                                   [(value)]="paragraph.value"
                                                                   [radioOptions]="yesNoOptions"
                                                                   class="iw-input col-xs-7"></iw-radio-list>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.DATE">
                                                    <iw-date-picker #txtDateFrom
                                                                    [(value)]="paragraph.value"
                                                                    [isDisabled]="paragraph.readonly"
                                                                    [labelAlign]="'top'"
                                                                    [label]="paragraph.label"
                                                                    class="iw-input col-xs-7"></iw-date-picker>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.DATETIME">
                                                    <iw-date-picker #txtDateFrom
                                                                    [(value)]="paragraph.value"
                                                                    [isDisabled]="paragraph.readonly"
                                                                    [labelAlign]="'top'"
                                                                    [label]="paragraph.label"
                                                                    class="iw-input col-xs-7"
                                                                    showTime="true"></iw-date-picker>
                                                </div>
                                                <div *ngSwitchCase="ANSWER_TYPE.LABEL">
                                                    <p class="iw-input col-xs-7">
                                                        {{ paragraph.label }} </p>
                                                </div>
                                                <ng-container *ngSwitchDefault>
                                                    <ul>
                                                        <li>id -> {{ paragraph.id }}</li>
                                                        <li>label -> {{ paragraph.label }}</li>
                                                        <li>readonly -> {{ paragraph.readonly }}</li>
                                                        <li>type -> {{ paragraph.type }}</li>
                                                        <li>default -> {{ paragraph.default }}</li>
                                                        <li>value -> {{ paragraph.value }}</li>
                                                    </ul>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <ng-container *ngSwitchDefault>
                                TODO IMPLEMENT
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </p-scrollPanel>
    </div>
    <div class="row">

        <iw-button (press)="getDialogMessage()"
                   [text]="'get_dialog' | translate"
                   class="iw-button no-label col-xs"></iw-button>
        <iw-button (press)="getDialogMessage(true)"
                   [text]="'get_dialog_forced' | translate"
                   class="iw-button no-label col-xs"></iw-button>
        <iw-button (press)="saveDialogMessage()"
                   *ngIf="isSaveDialogDisabled"
                   [text]="'save_dialog' | translate"
                   class="iw-button no-label col-xs"></iw-button>
        <iw-button (press)="replyDialog()"
                   [isDisabled]="isReplyDialogDisabled"
                   [text]="'reply_dialog' | translate"
                   class="iw-button no-label col-xs"></iw-button>
    </div>
</form>


