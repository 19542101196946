import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const sasx5decTabsFormControls: TabFormControl<Sasx5dec>[] = [
    {
        title: 'general',
        formControls: [
            'id',
            'status',
            'sxType',
            'sxMonthlyType',
            'sxYearlyType',
            'gestion',
            'annee',
            'month',
            'salId',
            'sxMode',
            'sxJobkey',
            'sxDeclid',
            'declRespId',
            'xmlReceived',
            'xmlSent',
            'isSubstitution',
            'prevRequestId',
            'prevResponseId']
    },
    {
        title: 'employees',
        formControls: []
    },
    {
        title: 'institutions',
        formControls: ['institutions']
    },
    {
        title: 'assurances',
        formControls: ['institutions']
    },
    {
        title: 'dataSent',
        formControls: []
    },
    {
        title: 'dataReceived',
        formControls: []
    },
    {
        title: 'viewgen',
        formControls: []
    }];
