<button (click)="debug()">debug</button>
<form [formGroup]="formGroup"
      class="row space-form">
    <div class="row col-xs-12 no-padding">
        <iw-textfield #txtCli_id
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'id' | translate"
                      [value]="id"
                      class="iw-input col-xs-9 no-padding"
                      formControlName="id"></iw-textfield>

        <iw-textfield #txtCliNom
                      [disabled]="usernameDisabled"
                      [isDisabled]="usernameDisabled"
                      [labelAlign]="'left'"
                      [label]="'utilisateur' | translate"
                      [value]="username"
                      class="iw-input no-padding col-xs-9"
                      formControlName="username"></iw-textfield>

        <iw-textfield #txtCliNom
                      [isDisabled]="false"
                      [labelAlign]="'left'"
                      [label]="'prenom' | translate"
                      [value]="firstName"
                      class="iw-input no-padding col-xs-9"
                      formControlName="firstName"></iw-textfield>

        <iw-textfield #txtCliNom
                      [isDisabled]="false"
                      [labelAlign]="'left'"
                      [label]="'nom' | translate"
                      [value]="lastName"
                      class="iw-input no-padding col-xs-9"
                      formControlName="lastName"></iw-textfield>

        <iw-textfield #txtCliNom
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'tenant' | translate"
                      [value]="tenant"
                      class="iw-input no-padding col-xs-9"
                      formControlName="tenant"></iw-textfield>
        <iw-email-textfield #txtEmail
                            [isDisabled]="false"
                            [labelAlign]="'left'"
                            [label]="'email' | translate"
                            [value]="email"
                            class="iw-input no-padding col-xs-9"
                            formControlName="email"></iw-email-textfield>

        <iw-enum-dropdown #txtPartype
                          [inputEnum]="options"
                          [isDisabled]="false"
                          [labelAlign]="'left'"
                          [label]="'langue' | translate"
                          class="iw-input no-padding col-xs-9"
                          formControlName="locale"></iw-enum-dropdown>
        <iw-multi-select #txtMsg_to
                         (selectedFromList)="onValueUpdated($event)"
                         [(value)]="gestions"
                         [allowNewEntries]="false"
                         [class.error]="gestions?.length === 0"
                         [defaultOptions]="availableGestions"
                         [labelAlign]="'left'"
                         [label]="'gestion' | translate"
                         [matchValues]="matchSelectedEntries"
                         [textSource]="matchSourceName"
                         [textTarget]="matchSourceName"
                         [value]="gestions"
                         class="iw-input no-padding col-xs-9"></iw-multi-select>
        <div class="row col-xs-12">

            <div class="iw-label col-xs-5 flex flex-center">{{ 'roles_available' | translate }}</div>
            <div class="iw-label col-xs-5 col-xs-offset-2 flex flex-center">{{ 'roles_linked' | translate }}</div>
            <iw-selection-lists (secondListChange)="onSecondListChange($event)"
                                [disabled]="isReadonly"
                                [firstList]="availableRoles"
                                [secondList]="actualRoles"
                                class="col-xs-12 no-padding"></iw-selection-lists>
        </div>

        <iw-textfield #txtPassword
                      [hidden]="isUpdate"
                      [isDisabled]="isUpdate"
                      [labelAlign]="'left'"
                      [label]="'password' | translate"
                      [type]="'password'"
                      [value]="password"
                      class="iw-input no-padding col-xs-9"
                      formControlName="password"></iw-textfield>
        <iw-textfield #txtPasswordConfirm
                      [hidden]="isUpdate"
                      [isDisabled]="isUpdate"
                      [labelAlign]="'left'"
                      [label]="'confirm_password' | translate"
                      [type]="'password'"
                      [value]="confirmPassword"
                      class="iw-input no-padding col-xs-9"
                      formControlName="confirmPassword"></iw-textfield>

        <div class="row col-xs-12 no-padding-right"
             style="padding-left: 10%">
            <iw-button #Standardbutton2
                       (press)="destroyForm()"
                       [isDisabled]="closeDisabled"
                       [text]="'fermer' | translate"
                       class="iw-button col-xs-2 no-label no-padding-right"></iw-button>

            <iw-button #Standardbutton2
                       (press)="saveSamUser()"
                       *ngIf="!userExists"
                       [hidden]="userExists"
                       [isDisabled]="createDisabled || !isFormValid"
                       [text]="'create' | translate"
                       class="iw-button col-xs-2 no-label no-padding-right"></iw-button>

            <iw-button #Standardbutton2
                       (press)="updateSamUser()"
                       *ngIf="userExists"
                       [hidden]="userExists"
                       [isDisabled]="updateDisabled || !isFormValid"
                       [text]="'save' | translate"
                       class="iw-button col-xs-2 no-label no-padding-right"></iw-button>

            <iw-button #Standardbutton2
                       (press)="deleteSamUser()"
                       [isDisabled]="deleteDisabled || !userExists"
                       [text]="'supprimer' | translate"
                       class="iw-button col-xs-2 no-label no-padding-right"></iw-button>

            <iw-button #Standardbutton2
                       (press)="resetPwdSamUser()"
                       [isDisabled]="!userExists || closeDisabled"
                       [text]="'reset_password' | translate"
                       class="iw-button col-xs-3 no-label no-padding-right"></iw-button>
        </div>
    </div>
    <iw-busy [isBusy]="isLoading"></iw-busy>
</form>

