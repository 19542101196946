import {EventEmitter, Injectable} from '@angular/core';
import {RestApiService} from '@app/sam-base/core';
import {environment} from '@root/environments/environment';
import {EmployeeDeclaration} from '@sam-base/models/admin/employee-declaration.model';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5decView} from '@sam-base/models/admin/sasx5decView.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {ViewgenReportCmd} from '@sam-base/models/admin/viewgen.model';
import {Gesaexe} from '@sam-base/models/invoices/gesaexe';
import {Saparass} from '@sam-base/models/placement/saparass';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class SwissdecService {

    public declareSalaryEvent = new EventEmitter<Sasx5dec>();

    constructor(private readonly _restApiService: RestApiService) {
    }

    public ping() {
        return this._restApiService.http
            .get(environment.backendURL + 'swissdec/ping');
    }

    public checkInteroperability() {
        return this._restApiService.http
            .get(environment.backendURL + 'swissdec/checkInteroperability');
    }


    public declareSalary(declarationId: string) {
        return this._restApiService.http
            .get(environment.backendURL + `swissdec/sasx5dec/${declarationId}/declare`)
            .subscribe({
                next: (dec) => this.declareSalaryEvent.emit(dec),
                error: () => this.declareSalaryEvent.emit(undefined)
            });
    }

    public getSasx5decAvailable() {
        return this._restApiService.http.get<Sasx5decView[]>(environment.backendURL + 'swissdec/available');
    }


    public mergeDeclarations(declarationIds: string[]) {
        return this._restApiService.http
            .post(environment.backendURL + `swissdec/sasx5dec/merge`, {}, {params: {ids: declarationIds.join(',')}});
    }

    public viewgen(declarationId: string, cmd: ViewgenReportCmd) {
        return this._restApiService.http
            .post(environment.backendURL + `swissdec/sasx5dec/${declarationId}/viewgen`, cmd, {responseType: 'arraybuffer'})
            .pipe(map(data => {
                // TODO adapt depending on multi or not
                const mimeType = 'application/pdf';
                const name = `${cmd.filename}.pdf`;
                const blobData = new Blob([data], {type: mimeType});
                saveAs(blobData, name);
                return blobData;
            }));
    }

    public getExerciseForGestion(gestion: string): Observable<Gesaexe[]> {
        return this._restApiService.http.get<Gesaexe[]>(environment.backendURL + `swissdec/exercise/${gestion}`);
    }

    public getInsurancesForGestionAndYear(gestion: string, year: string): Observable<Saparass[]> {
        return this._restApiService.http.get<Saparass[]>(environment.backendURL + `swissdec/insurances/${gestion}/${year}`);
    }


    loadEmployees(declarationId: string) {
        return this._restApiService.http.get<EmployeeDeclaration[]>(environment.backendURL + `swissdec/sasx5dec/${declarationId}/employees`);
    }

    loadInstitutions(declarationId: string) {
        return this._restApiService.http.get<Sasx5institution[]>(environment.backendURL + `swissdec/sasx5dec/${declarationId}/institutions`);
    }
}
