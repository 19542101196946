<form [formGroup]="formGroup"
      class="row spaceForm">
    <div class="form-host row col-xs-12">
        <!-- GESTION -->
        <div class="row col-xs-9 no-padding">
            <iw-textfield [isDisabled]="true"
                          [label]="'gestion' | translate"
                          [tooltipPosition]="'right'"
                          [tooltip]="'gestion' | translate"
                          class="no-padding-right"
                          formControlName="gestion"
                          labelAlign="left"
                          size="small"></iw-textfield>
            <iw-button #btnEnf
                       (press)="onBtnGestionClick()"
                       [isDisabled]="isReadonly || !isNew"
                       [tooltip]="'select_gestion' | translate"
                       class="iw-button col-xs-1 no-padding-left"
                       style="margin-bottom: 4px;"
                       text="..."></iw-button>
        </div>
        <!--  ANNEE  -->
        <div class="row col-xs-9 no-padding">
            <iw-textfield [isDisabled]="true"
                          [label]="'annee' | translate"
                          [tooltipPosition]="'right'"
                          [tooltip]="'annee' | translate"
                          class="no-padding-right"
                          formControlName="annee"
                          labelAlign="left"
                          size="small"></iw-textfield>
            <iw-button #btnEnf
                       (press)="onBtnAnneeClick()"
                       [isDisabled]="isReadonly || !isNew || !gestion"
                       [tooltip]="'select_annee' | translate"
                       class="iw-button col-xs-1 no-padding-left"
                       style="margin-bottom: 4px;"
                       text="..."></iw-button>
        </div>

        <iw-dropdown #drpPeriod
                     [isDisabled]="isReadonly || !isNew || !annee"
                     [labelAlign]="'right'"
                     [label]="'combo_periode_salaire' | translate"
                     [options]="optionPeriode"
                     class="iw-input col-xs-9 no-padding"
                     formControlName="month"></iw-dropdown>

        <iw-enum-dropdown #drpMonthlyType
                          *ngIf="isMonthlyType"
                          [inputEnum]="optionsSxMonthlyType"
                          [isDisabled]="isReadonly || !isNew"
                          [labelAlign]="'right'"
                          [label]="'combo_sx_monthly_type' | translate"
                          [translationPrefix]="'sxMonthlyType'"
                          class="iw-input col-xs-9 no-padding"
                          formControlName="sxMonthlyType"></iw-enum-dropdown>
        <iw-enum-dropdown #drpYearlyType
                          *ngIf="isYearlyType"
                          [inputEnum]="optionsSxYearlyType"
                          [isDisabled]="isReadonly || !isNew"
                          [labelAlign]="'right'"
                          [label]="'combo_sx_yearly_type' | translate"
                          [translationPrefix]="'sxYearlyType'"
                          class="iw-input col-xs-9 no-padding"
                          formControlName="sxYearlyType"></iw-enum-dropdown>
        <iw-enum-dropdown #drpType
                          [inputEnum]="optionsSxMode"
                          [isDisabled]="isReadonly || !isNew"
                          [labelAlign]="'right'"
                          [label]="'combo_sx_mode' | translate"
                          [translationPrefix]="'sxMode'"
                          class="iw-input col-xs-9 no-padding"
                          formControlName="sxMode"></iw-enum-dropdown>
        <!--  SUBSTITUTION INFO -->
        <ng-template [ngIf]="isSubstitutionPossible">
            <iw-checkbox [isDisabled]="isReadonly || !isNew"
                         [label]="'is_substitution' | translate"
                         [size]="'large'"
                         class="iw-input col-xs-6 margin-top-5"
                         formControlName="isSubstitution"></iw-checkbox>
            <ng-template [ngIf]="isSubstitution">
                <iw-textfield [isDisabled]="isReadonly || !isNew"
                              [labelAlign]="'top'"
                              [label]="'prev_request_id' | translate"
                              class="iw-input col-xs-9 no-padding"
                              formControlName="prevRequestId"></iw-textfield>
                <!--      <iw-textfield-->
                <!--        class="iw-input no-padding col-xs-9"-->
                <!--        formControlName="prevResponseId"-->
                <!--        [isDisabled]="isReadonly || !isNew"-->
                <!--        [label]="'prev_response_id' | translate"-->
                <!--        [labelAlign]="'left'"></iw-textfield>-->
            </ng-template>
        </ng-template>
        <!--  END SUBSTITUTION INFO -->

        <iw-textfield #txtCli_id
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'id' | translate"
                      [value]="entityId"
                      class="iw-input col-xs-9 no-padding"
                      formControlName="id"></iw-textfield>
        <iw-textfield #txtSalId
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'salId' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="salId"></iw-textfield>
        <iw-textfield #txtSxJobkey
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'sxJobkey' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="sxJobkey"></iw-textfield>
        <iw-textfield #txtSxDeclid
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'sxDeclid' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="sxDeclid"></iw-textfield>
        <iw-textfield #txtDeclrespid
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'declrespid' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="declRespId"></iw-textfield>
    </div>
</form>
