import {HttpClient} from '@angular/common/http';

import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {AgencyHandlerService, RestEntityClient, TabFormControl} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {FormKeys} from '@app/sam-base/models';
import {sasx5decTabsFormControls} from '@modules/sam-main/admin/components/swissdec/sasx5dec-tabs-formcontrols';
import {environment} from '@root/environments/environment';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';
import {Sasx5dec, SxNotificationBundle} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxType} from '@sam-base/models/admin/sx-type.enum';
import {SxYearlyType} from '@sam-base/models/admin/sx-yearly-type.enum';
import {Subject, takeUntil} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
    templateUrl: './swissdec.component.html'
})
export class SwissdecComponent extends BaseTabFormComponent<Sasx5dec> implements OnInit, OnDestroy, AfterViewInit {

    public getDeclarationStatusDisabled = false;
    public closeDisabled = false;
    public isUpdate = false;
    public delete = false;
    public isLoading = false;
    public institutions: Sasx5institution[] = [];
    public disabledTabList: string[] = [];
    private subscriptions = new Subject();
    private readonly sasx5decClient: RestEntityClient<Sasx5dec>;
    private readonly tabValues?: TabFormControl<Sasx5dec>[];

    constructor(store: IwStoreService, private readonly _restService: RestApiService,
                private readonly _http: HttpClient,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private readonly _toast: ToastService, protected readonly _store: IwStoreService,
                private readonly _agencyHandlerService: AgencyHandlerService) {
        super(store);
        this.tabValues = getDeepCopy(sasx5decTabsFormControls);
        this.sasx5decClient = this._restService.getEntityClient(Sasx5dec);
    }

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
        }
    }

    public get isCreateMode(): boolean {
        return !this.getData('row');
    }

    public get id() {
        return this.getFormValue('id') || '';
    }

    public get titleInfo(): string {
        const gestion = this.getFormValue('gestion') || '';
        const decType = this.getFormValue('sxType') || '';
        const salId = this.getFormValue('salId') || '';
        return `${gestion} - ${decType} - ${salId}`;
    }

    public get status(): string {
        return this.getFormValue('status') || '';
    }

    public get xmlSent(): string {
        return this.getFormValue('xmlSent');
    }

    public get xmlReceived(): string {
        return this.getFormValue('xmlReceived');
    }

    public get declarationExists(): boolean {
        return !!this.getFormValue('id');
    }

    public get errorBundle(): BusinessExceptionErrors | undefined {
        return this.getFormValue('error');
    }

    public get notifications(): SxNotificationBundle | undefined {
        return this.getFormValue('notifications');
    }

    public ngOnInit() {

        if (this.isCreateMode) {
            this.setFormValue('sxType', this.getData('creationType') as SxType);
        }
        this.handleDynamicFormControls();
        this.subscribeValueChange<string>('id', e => {
            this.handleDynamicFormControls();
            if (!e) {
                this.disabledTabList = [];
                return;
            }
            this.showHideTabs(!e);
            this.refreshFormMessageService.sendMessage('sasx5dec.table');
        });
        this.subscribeValueChange('sxYearlyType', yearlyType => {
            this.handleDynamicFormControls();
            this.showHideTabs(this.isCreateMode);
        });


        const declaration: Sasx5dec | undefined = this.getData('row');
        // if (declaration && declaration.id) {
        //     this.isUpdate = true;
        //     this.setFormValue('id', declaration.id);
        //     // Todo create an enum of some kind instead of string
        //     this.refreshFormMessageService.sendMessage('sasx5dec.table');
        //     this.sasx5decClient.getById(declaration.id)
        //         .subscribe(decl => {
        //             this.setFormValues(decl);
        //         });
        // }
        this.showHideTabs(!declaration?.id);
    }

    public ngAfterViewInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .pipe(filter(e => !!e))
            .subscribe((data: any) => {
                this.fillFormData(data);
            });
    }

    public ngOnDestroy() {
        this.refreshFormMessageService.sendMessage('sasx5dec.table');
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getResultFromDeclareSalary(institution: Sasx5institution) {
        this.isLoading = true;
        return this._http.get<any>(// eslint-disable-next-line max-len
            environment.backendURL + `swissdec/institutions/${institution.id}/get-result-from-declare-salary`)
            .subscribe(inst => {
                this._toast.success('get_result_from_declare_salary_success');
                this.isLoading = false;
                this.isUpdate = true;
            }, (err) => {
                this._toast.error('get_result_from_declare_salary_error');
                this.isLoading = false;
                this.closeDisabled = false;
                this.getDeclarationStatusDisabled = false;
            });
    }

    public getTabsValues(): TabFormControl<Sasx5dec>[] {
        if (!this.tabValues) {
            return sasx5decTabsFormControls;
        }
        return this.tabValues;
    }

    public debug() {
        console.log('FORM DATA', this.getFormData());
        console.log('form', this.formGroup);
    }

    protected getFormControlNames(): FormKeys<Sasx5dec> {
        return [
            'id',
            [
                'annee',
                new UntypedFormControl(undefined, Validators.required)],
            [
                'gestion',
                new UntypedFormControl(undefined, Validators.required)],
            'salId',
            'sxJobkey',
            'sxDeclid',
            'declRespId',
            'xmlReceived',
            'xmlSent',
            'sxType', // Maybe required only for certification case
            [
                'month',
                new UntypedFormControl(undefined, Validators.required)],
            [
                'sxMonthlyType',
                this.getData('creationType') as SxType.MONTHLY ? new UntypedFormControl(undefined, Validators.required) : new UntypedFormControl(undefined)],
            [
                'sxYearlyType',
                this.getData('creationType') as SxType.ANNUALLY ? new UntypedFormControl(undefined, Validators.required) : new UntypedFormControl(undefined)],
            [
                'sxMode',
                new UntypedFormControl(undefined, Validators.required)],
            'status',
            'notifications',
            'prevRequestId',
            'prevResponseId',
            'isSubstitution',
            'prevRequestId',
            'prevResponseId'];
    }

    protected getValidationType() {
        return Sasx5dec;
    }

    private setEntityId(v: string) {
        if (v !== this.id) {
            this.setFormValue('id', v);
        }
    }

    private showHideTabs(isCreateMode: boolean) {
        const controls = this.tabValues ?? getDeepCopy(sasx5decTabsFormControls);
        controls.forEach((control: TabFormControl<Sasx5dec>) => {
            if (control.title === 'institutions') {
                control.show = !isCreateMode;
            }
            if (control.title === 'employees') {
                control.show = !isCreateMode;
            }
            if (control.title === 'assurances') {
                control.show = isCreateMode && (this.getFormValue('sxType') === SxType.ANNUALLY && this.getFormValue('sxYearlyType') === SxYearlyType.ANNUAL_STATEMENT);
            }
        });

        this.refreshTabList();
    }

    private handleDynamicFormControls() {
        // If yearly declaration statement, then we can choose the institutions/assurances, otherwise, it is forced in the back end
        if (this.getFormValue('sxType') === SxType.ANNUALLY && this.getFormValue('sxYearlyType') === SxYearlyType.ANNUAL_STATEMENT) {
            this.registerControl('institutions', new UntypedFormControl([]));
        } else {
            this.formGroup.removeControl('institutions');
        }

        if (this.getFormValue('id')) {
            // In edition state, with an existing declaration, we don't want to edit the institutions tab
            this.formGroup.removeControl('institutions');
        }

    }

}
