import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SxNotification, SxNotificationBundle} from '@sam-base/models/admin/sasx5dec.model';

import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {SxResponseState} from '@sam-base/models/admin/sx-response-state.enum';
import {Subject} from 'rxjs';

/**
 * Tab General of Swissdec Declaration
 */
@Component({
    selector: 'iw-tab-sasx5institution-general',
    templateUrl: './tab-sasx5institution-general.component.html',
    styleUrls: ['./tab-sasx5institution-general.component.scss']
})
export class TabSasx5institutionGeneralComponent extends BasePartialFormComponent<Sasx5institution> implements OnInit, OnDestroy {


    @Input() public isNew = false;
    public sxNotifications: SxNotification[] = [];
    private subscriptions = new Subject();

    constructor() {
        super();
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get expectedAvailability(): string | undefined {
        return this.getFormValue('expectedAvailability');
    }

    public get notifications(): SxNotificationBundle | undefined {
        return this.getFormValue('notifications');
    }

    public get domain(): string {
        return this.getFormValue('domain') || '';
    }

    public get isCompletionReleaseIsMissing(): boolean {
        return this.getFormValue('state') === SxResponseState.COMPLETION_RELEASE_IS_MISSING;
    }

    public get assNocai(): string {
        return this.getFormValue('assNocai') || '';
    }

    public get assNocli(): string {
        return this.getFormValue('assNocli') || '';
    }

    public get subtitle(): string {
        return `${this.domain} - ${this.assNocai} - ${this.assNocli}`;
    }

    public get xmlReceived(): string | undefined {
        return this.getFormValue('xmlReceived');
    }

    public get institutionState(): string {
        return this.getFormValue('state') || '';
    }

    public get isProcessFlowUrl(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.COMPLETION_AND_RESULT;
    }

    public get isProcessFlowDialog(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.DIALOG_AND_RESULT;
    }

    public get isProcessFlowResult(): boolean {
        return this.getFormValue('processFlow') === SxProcessFlow.RESULT;
    }

    public get userMessage(): string {
        return this.getFormValue('userMessage') || '';
    }

    public get completionUrl(): string {
        return this.getFormValue('completionUrl') || '';
    }

    public get login(): string {
        return this.getFormValue('login') || '';
    }

    public get password(): string {
        return this.getFormValue('password') || '';
    }

    public ngOnInit() {
        this.subscribeValueChange('userMessage', (notifications) => this.parseNotifications(notifications as string));
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    private parseNotifications(notificationsString: string) {
        if (!notificationsString) {
            return;
        }
        const notifMessagesArray = notificationsString.split(';');
        this.sxNotifications = notifMessagesArray
            .filter(msg => msg !== '')
            .map((errorMessage) => {
                const [type, level, codeString, description] = errorMessage.split('::');
                const code = +codeString;

                return {
                    type,
                    code,
                    level,
                    description
                };
            });
    }


}
