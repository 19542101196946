import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {LoadEntity, SetLoading} from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {IwEventHubService} from '@sam-base/core';
import {Sasx5dec, SwissdecUIEvents} from '@sam-base/models/admin/sasx5dec.model';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {Ppemp} from '@sam-base/models/placement';
import {finalize, Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './sasx5dec-side-menu.component.html'
})
export class Sasx5decSideMenuComponent extends BaseSideFormComponent<Sasx5dec> implements OnInit, AfterViewInit, OnDestroy {

    public declareSalaryDisabled = false;
    public gettingStatus = false;
    public declaration?: Sasx5dec;
    private readonly _empRest: RestEntityClient<Ppemp>;
    private subscriptions = new Subject();

    constructor(private readonly _store: IwStoreService,
                private readonly _event: IwEventHubService<SwissdecUIEvents>,
                private readonly _toastService: ToastService, private readonly _modalService: ModalService,
                private readonly _translate: TranslateService, private readonly _http: HttpClient,
                private readonly _toast: ToastService, private readonly _swissdecService: SwissdecService,
                _restService: RestApiService) {
        super();
        this._empRest = _restService.getEntityClient(Ppemp);
    }

    public get getStatusDisabled(): boolean {
        // eslint-disable-next-line max-len
        return this.gettingStatus || [
            SxStatus.DONE,
            SxStatus.GET_STATUS_OK,
            SxStatus.INIT,
            SxStatus.INIT_ERROR].indexOf(this.declaration?.status || SxStatus.INIT) !== -1;
    }

    public ngOnInit() {
    }

    public ngOnDestroy(): void {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public ngAfterViewInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.declaration = data;
                // eslint-disable-next-line max-len
                this.declareSalaryDisabled = [
                    SxStatus.INIT,
                    SxStatus.INIT_ERROR].indexOf(this.declaration?.status || SxStatus.DONE) === -1;
            });
    }


    public declareSalary() {

        this._store.dispatch(new SetLoading(this.uuid, true));
        this.declareSalaryDisabled = true;
        return this._http.get<any>(environment.backendURL + `swissdec/sasx5dec/${this.declaration?.id}/declare`)
            .pipe(finalize(() => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                this.declareSalaryDisabled = false;
                this._event.emit(SwissdecUIEvents.DECLARE_SALARY_DONE, {
                    declarationId: this.declaration?.id,
                    toto: 'aaaa'
                });
            }))
            .subscribe(sasx5dec => {
                this.declaration = sasx5dec;
                if (this.declaration?.status === SxStatus.INIT_ERROR) {
                    this._toast.warning('declaration_declare_salary_fault');
                    this.declareSalaryDisabled = false;
                } else {
                    this._toast.success('declaration_declare_salary_success');
                    this.declareSalaryDisabled = true;
                }
                this._store
                    .dispatch(new LoadEntity(this.uuid));
            });
    }

    public getDeclarationStatus() {

        this._store.dispatch(new SetLoading(this.uuid, true));
        this.gettingStatus = true;
        return this._http.get<any>(environment.backendURL + `swissdec/sasx5dec/${this.declaration?.id}/status`)
            .subscribe(sasx5dec => {
                this.declaration = sasx5dec;
                if (this.declaration?.status === SxStatus.GET_STATUS_ERROR) {
                    this._toast.warning('declaration_get_declaration_status_fault');
                    this.gettingStatus = false;
                } else if (this.declaration?.status === SxStatus.GET_STATUS_PROCESSING) {
                    this._toast.success('declaration_get_declaration_status_processing');
                    setTimeout(() => {
                        this.gettingStatus = false;
                    }, 10000);
                } else {
                    this._toast.success('declaration_get_declaration_status_success');
                    this.gettingStatus = false;
                }
                this._store.dispatch(new SetLoading(this.uuid, false));
                this._store
                    .dispatch(new LoadEntity(this.uuid));

            }, (err) => {
                this._toast.error('declaration_get_declaration_status_error');
                this._store.dispatch(new SetLoading(this.uuid, false));
                this.gettingStatus = false;
            });
    }

}
